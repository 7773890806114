<template>
    <div class="main-transactions">
        <div class="transactions-general-container">
            <div class="transactions">
                <Header v-if="isHeaderActive" />
                <Table 
                    :items="items" 
                    :headers="headers" 
                    :handleRefreshTransactions="handleRefreshTransactions"
                    :isTransactions="true" 
                    :headerText="'generalTabs.transactions'"
                    :buttonText="'generalTabs.addTransactionManually'" 
                    :helperText="'generalTabs.madeATransaction'" />
            </div>

        </div>
        <Envelope />
    </div>
</template>

<script>

export default {
    props: ['items', 'headers', 'handleRefreshTransactions', 'isHeaderActive'],

    components: {
        Header: () => import('@/components/SideNavPc.vue'),
        Envelope: () => import('@/components/Envelope.vue'),
        Table: () => import('./PremiumClubTable.vue')
    }
}
</script>

<style scoped>
.main-transactions {
    display: flex;
    justify-content: space-between;
}

.transactions {
    width: 100%;
}

@media only screen and (max-width: 1500px) {
    .transactions-general-container {
        width: 70%;
    }

    .transactions {
        width: 100%;
    }
}

@media only screen and (max-width: 1090px) {

    .main-transactions {
        flex-direction: column;
        align-items: center;
    }

    .transactions-general-container {
        width: 100%;
    }
}
</style>